/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Link, useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.scss"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
      <div className="layout">
      <Header siteTitle={data.site.siteMetadata.title} />
        <main>{children}</main>
        <footer className="footer">
          <div className="content has-text-centered">
            <p>
              <strong className="is-white-color">
                Kiinteistövarainhoito-yhtiöt
                </strong>
              - asiakaslähtöistä kiinteistöliiketoimintaa
            </p>
            <Link to="/yhteystiedot" style={{textDecoration: "none", color: "#fff"}}>
              Kiinnostuitko? <strong className="has-text-weight-bold" style={{color:"#8f8f8f"}}>Ota yhteyttä</strong>
            </Link>
          </div>
        </footer>
      </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
