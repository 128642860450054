import { useState } from 'react'
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  }

  const data = useStaticQuery(graphql`
  query headerQuery {
  logo: file(relativePath: {eq: "kvh_transparent.png"}) {
    childImageSharp {
      fixed(width: 45, height: 90) {
        ...GatsbyImageSharpFixed
     }
    }
  }
}
  `)

  return (
    <section className="section is-paddingless has-background-light">
      <header className="hero is-primary" id="header-div">
        <div className="hero-body">
          <div className="container header-container is-flex is-centered">
            <Link to="/"><Img fixed={data.logo.childImageSharp.fixed}></Img></Link>
            <h1 className="flex-center header-title title is-4 is-size-5-mobile is-uppercase has-text-weight-light">{siteTitle}</h1>
          </div>
        </div>
      </header>
      <div className="container navbar-flex">
      <nav className="navbar has-background-light" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <a
            className={open ? "navbar-burger is-active" : "navbar-burger"}
            role="button"
            onClick={toggleOpen}
            data-target="navMenu"
            aria-label="menu"
            aria-expanded="false"
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
          <div className="navbar-end">
          </div>
        </div>
        <div className={open ? "navbar-menu nav-menu-mobile is-active" : "navbar-menu"} id="navMenu">
          <div className="navbar-start">
            <Link className="navbar-item is-uppercase" onClick={() => setOpen(false)} to="/">Etusivu</Link>
            <Link className="navbar-item is-uppercase" onClick={() => setOpen(false)} to="/tietoa-kohteesta">Tietoa kohteesta</Link>
            <Link className="navbar-item is-uppercase" onClick={() => setOpen(false)} to="/yhteystiedot">Yhteystiedot</Link>
          </div>
        </div>
        <div className="navbar-end">
        </div>
      </nav>
      </div>
    </section>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
